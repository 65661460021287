///
/// Eventually by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* BG */

	#bg {
		@include vendor('transition', 'opacity #{_duration(bg-fadein)} ease-in-out');
		height: 100%;
		left: 0;
		opacity: 0.375;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 1;

		div {
			@include vendor('transition', ('opacity #{_duration(bg-transition)} ease'));
			background-size: cover;
			height: 100%;
			left: 0;
			opacity: 0;
			position: absolute;
			top: 0;
			visibility: hidden;
			width: 150%;

			&.visible {
				@include vendor('animation', 'bg #{_duration(bg-slide)} linear infinite');
				opacity: 1;
				visibility: visible;
				z-index: 1;

				&.top {
					z-index: 2;
				}

				@include breakpoint('<=large') {
					@include vendor('animation', 'bg #{_duration(bg-slide) * 0.65} linear infinite');
				}

				@include breakpoint('<=small') {
					@include vendor('animation', 'bg #{_duration(bg-slide) * 0.4} linear infinite');
				}
			}

			&:only-child {
				@include vendor('animation-direction', 'alternate !important');
			}
		}

		body.is-preload & {
			opacity: 0;
		}
	}

	@include keyframes(bg) {
		0% {
			@include vendor('transform', 'translateX(0)');
		}

		100% {
			@include vendor('transform', 'translateX(-25%)');
		}
	}