// Misc.
	$misc: (
	);

// Duration.
	$duration: (
		nav:				0.5s,
		transition:			0.2s,
		bg-fadein:			2s,
		bg-transition:		3s,
		bg-slide:			45s // (lower = faster, higher = slower)
	);

// Size.
	$size: (
		border-radius:		6px,
		border-width:		2px,
		element-height:		2.75em,
		element-margin:		2em
	);

// Font.
	$font: (
		family:				('Roboto', sans-serif),
		family-fixed:		('Courier New', monospace),
		weight:				400,
		weight-bold:		700,
		letter-spacing:		-0.01em
	);

// Palette.
	$palette: (
		bg:					#000,
		fg:					rgba(255,255,255,0.75),
		fg-bold:			#fff,
		fg-light:			rgba(255,255,255,0.5),
		border:				rgba(255,255,255,0.35),
		border-bg:			rgba(255,255,255,0.125),
		border2:			rgba(255,255,255,0.25),

		accent: (
			bg:				#048A80,
			fg:				mix(#048A80, #ffffff, 25%),
			fg-bold:		#ffffff,
			fg-light:		mix(#1F9990, #ffffff, 40%),
			border:			rgba(255,255,255,0.25),
			border-bg:		rgba(255,255,255,0.075),
		),

		positive: (
			bg:				#048A80,
			fg:				#ffffff
		),

		negative: (
			bg:				#ff2361,
			fg:				#ffffff
		)
	);