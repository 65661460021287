///
/// Eventually by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Basic */

	// MSIE: Required for IEMobile.
		@-ms-viewport {
			width: device-width;
		}

	// MSIE: Prevents scrollbar from overlapping content.
		body {
			-ms-overflow-style: scrollbar;
		}

	// Ensures page width is always >=320px.
		@include breakpoint('<=xsmall') {
			html, body {
				min-width: 320px;
			}
		}

	// Set box model to border-box.
	// Based on css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
		html {
			box-sizing: border-box;
		}

		*, *:before, *:after {
			box-sizing: inherit;
		}

	html, body {
		height: 100%;
		overflow-x: hidden;
		width: 100%;

		@include breakpoint('short') {
			height: auto;
			min-height: 100%;
		}
	}

	body {
		@include vendor('display', 'flex');
		@include vendor('flex-direction', 'column');
		@include vendor('justify-content', 'center');
		background-color: _palette(bg);
		padding: 6em 4em 4em 4em;

		// Stops initial animations until page loads.
			&.is-preload {
				*, *:before, *:after {
					@include vendor('animation', 'none !important');
					@include vendor('transition', 'none !important');
				}
			}

		> * {
			position: relative;
			z-index: 2;
		}

		@include breakpoint('<=xlarge') {
			padding: 6em 3.5em 3.5em 3.5em;
		}

		@include breakpoint('<=small') {
			padding: 5em 2em 2em 2em;
		}

		@include breakpoint('<=xxsmall') {
			padding: 5em 1.25em 1.25em 1.25em;
		}
	}